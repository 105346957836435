import request from '@/utils/request'
export function search (data) {
  return request({
    url: '/video/search',
    method: 'post',
    data
  })
}
export function getVideo (id) {
  return request({
    url: '/video/' + id,
    method: 'get'
  })
}
export function getPage (page, noitems) {
  return request({
    url: '/video/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function storeVideo (data) {
  return request({
    url: '/video',
    method: 'post',
    data
  })
}
export function updateVideo (id, data) {
  return request({
    url: '/video/' + id,
    method: 'put',
    data
  })
}
export function destroyVideo (_id) {
  return request({
    url: '/video/' + _id,
    method: 'delete'
  })
}
export function exportExcel (data) {
  return request({
    url: '/video/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
