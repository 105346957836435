<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Videoteca</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <el-form label-position="top" label-width="100px" :model="form">
              <el-form-item label="Título" size="mini">
                <ValidationProvider name="Titulo" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span>
                  <span>Caracteres permitidos: 0/"60"</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Link video" size="mini">
                <!-- <ValidationProvider name="linkVideo" rules="required" v-slot="{ errors }"> -->
                  <el-input v-model="form.videoLink" placeholder="https:"></el-input>
                  <span><strong>https</strong>://vimeo.com/578105796</span><br>
                  <!-- <span>{{ errors[0] }}</span> -->
                <!-- </ValidationProvider> -->
              </el-form-item>
              <el-form-item label="Vimeo share" size="mini">
                <ValidationProvider name="vimeoShare" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.videoShare" placeholder="https:"></el-input>
                  <span>{{ errors[0] }}</span>
                  <span><strong>https</strong>://vimeo.com/578105796</span><br>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripción corta" size="mini">
                <ValidationProvider name="description" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.description"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Imagen">
                <div v-if="form.placeholder">
                  <el-image :src="$urlGlobalApi +form.placeholder">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <el-upload
                  action=""
                  drag
                  name="image"
                  :http-request="postImage"
                  :on-success="handleAvatarSuccess"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Suelta tu video aquí o <em>haz clic para cargar</em></div>
                  <div slot="tip" class="el-upload__tip">http://placehold.it/720x405</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="Contenido">
                <tinyeditor v-model="form.content"
                  api-key='epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex'
                    :init="{
                      height: 500,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                  />
              </el-form-item>
              <el-form-item label="Palabras Clave">
                <ValidationProvider name="keyword" rules="required" v-slot="{ errors }">
                  <el-select
                  size="mini"
                  v-model="form.category"
                  multiple
                  filterable
                  allow-create
                  placeholder="Comunicación">
                    <el-option
                      v-for="itemT in optionsTags"
                      :key="itemT.value"
                      :label="itemT.label"
                      :value="itemT.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Fecha de publicación">
                <ValidationProvider name="Fecha de publicación" rules="required" v-slot="{ errors }">
                  <el-date-picker
                    size="mini"
                    v-model="form.datePublication"
                    type="date"
                    placeholder="Fecha">
                  </el-date-picker>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
               <el-form-item label="Estatus">
                <el-switch
                  v-model="form.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
              <!-- <el-form-item label="Estatus">
                <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                  <el-select
                  size="mini"
                  v-model="form.status"
                  filterable
                  allow-create
                  placeholder="Seleccionar">
                    <el-option
                      v-for="item in optionStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span><br>
                </ValidationProvider>
                <span>Si el estatus es <strong>No</strong> el artículo no estara visible dentro del sitio</span>
              </el-form-item>-->
              <el-form-item>
              <el-button
                @click.stop.prevent="onUpdate"
                type="primary"
                size="mini"
                :disabled="invalid"
                :loading="loading"
                icon="el-icon-upload2">
                Actualizar</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { getVideo, updateVideo } from '@/api/video'
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      form: {
        videoLink: '',
        videoShare: '',
        title: '',
        description: '',
        placeholder: '',
        thumb: '',
        content: '',
        category: [],
        datePublication: '',
        status: true
      },
      optionsTags: [{
        value: 'Soporte',
        label: 'Soporte'
      }, {
        value: 'Marketing',
        label: 'Marketing'
      }, {
        value: 'Ventas',
        label: 'Ventas'
      }, {
        value: 'Finanzas',
        label: 'Finanzas'
      }, {
        value: 'Apps',
        label: 'Apps'
      }],
      loading: false,
      optionStatus: [{
        value: true,
        label: 'Si'
      }, {
        value: false,
        label: 'No'
      }]
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      await getVideo(this.$route.params._id)
        .then((response) => {
          this.form = response.data
          console.log(response.data)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async onUpdate () {
      await updateVideo(this.$route.params._id, this.form)
        .then((response) => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Video Actualizado!',
            type: 'success'
          })
          this.$router.push({ name: 'video' })
        })
        .catch(this.responseCatch)
    },
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('folder', 'img/video')
      formData.append('width', '800')
      formData.append('height', '450')
      formData.append('title', 'imagen800x450')
      formData.append('alt', 'videoteca-imagen')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccess (res, file) {
      this.form.placeholder = res.path
      this.form.thumb = res.pathThumb
    },
    clearFilter () {
      this.$refs.filterTable.clearFilter()
    }
  }
}
</script>
